<template>
  <div class="memberCenter">
    <div class="memberHeader flex">
      <div class="userInfo flex">
        <img src="../../assets/images/logo.png" />
        <div class="nameBox">
          <h3>{{ userName }}</h3>
          <p>ID:{{ userId }}</p>
        </div>
      </div>
      <div class="authInfo flex">
        <div class="authItem flex-col flex-center">
          <p>{{ $t("userText.primaryCertification") }}</p>
          <span :class="isPrimaryAuth ? 'blue' : 'red'">{{ primaryAuth }}</span>
        </div>
        <div class="authItem flex-col flex-center">
          <p>{{ $t("userText.advancedCertification") }}</p>
          <span :class="isAdvancedAuth ? 'blue' : 'red'">{{
            advancedAuth
          }}</span>
        </div>
      </div>
    </div>
    <div class="authContainer">
      <!-- 初级 -->
      <div class="detailAhtuItem">
        <h2>{{ $t("userText.primaryCertification") }}</h2>
        <p>{{ $t("userText.userInfo") }}</p>
        <p>{{ $t("userText.cardInfo") }}</p>
        <div
          @click="handPrimary"
          class="authBtn flex flex-center"
          :class="isPrimaryAuth ? 'greenBg' : ''"
        >
          {{
            isPrimaryAuth ? $t("userText.hasAuth") : $t("userText.startBegin")
          }}
        </div>
        <p class="authTip" v-if="isPrimaryAuth">{{ $t("userText.authTip") }}</p>
      </div>
      <!-- 高级 -->
      <div class="detailAhtuItem">
        <h2>{{ $t("userText.advancedCertification") }}</h2>
        <p>{{ $t("userText.userInfo") }}</p>
        <p>{{ $t("userText.idCardInfo") }}</p>
        <div
          @click="handAdvanced"
          class="authBtn flex flex-center"
          :class="isAdvancedAuth ? 'greenBg' : ''"
        >
          {{
            isAdvancedAuth ? $t("userText.hasAuth") : $t("userText.startBegin")
          }}
        </div>
        <div class="areaBox" v-if="isAdvancedAuth">
          <div class="areItem">
            <span>{{ $t("userText.cityName") }}：</span>
            <span>{{ countryInfo.countryname }}</span>
          </div>
          <div class="areItem">
            <span>{{ $t("userText.userName") }}：</span>
            <span>{{ countryInfo.name }}</span>
          </div>
          <div class="areItem">
            <span>{{ $t("userText.sureName") }}：</span>
            <span>{{ countryInfo.surname }}</span>
          </div>
          <div class="areItem">
            <span>{{ $t("userText.cardType") }}：</span>
            <span>{{ getNowCard(countryInfo.card_type) }}</span>
          </div>
          <div class="areItem">
            <span>{{ $t("userText.cardNumber") }}：</span>
            <span>{{ countryInfo.card_id }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <userSettings></userSettings>
   
    <authDig
      v-if="showAuth"
      @close="closeAuth"
      @resetData="resetData"
      :authType="authType"
    ></authDig>
  </div>
</template>
<script setup>
import userSettings from '@/components/memberCenter/userSettings.vue'
import { computed, onMounted, ref, getCurrentInstance } from "vue";
import { Message } from "element-ui";
import authDig from "@/components/memberCenter/authDig.vue";
import { useOption } from "@/hooks/useOption";
import { getrealinfo } from "@/api/user";
import { useStore } from "@/utils/vueApi";
const store = useStore();
const vm = getCurrentInstance();
const {  getNowCard } = useOption();
// 认证状态
const authStatus = computed(() => {
  return store.state.user.userInfo.review_status;
});
const userName = computed(() => {
  return store.state.user.userInfo.name;
});
const userId = computed(() => {
  return store.state.user.userInfo.id;
});
// 是否完成初级
const isPrimaryAuth = computed(() => {
  return authStatus.value == 2 || authStatus.value == 3 ? true : false;
});
// 是否完成高级
const isAdvancedAuth = computed(() => {
  return authStatus.value == 3 ? true : false;
});
// review_status  0：未认证， 1：审核中， 2：初级认证成功， 3是：级认证成功
// 初级认证
const primaryAuth = computed(() => {
  let text;
  if (authStatus.value == 1) {
    text = vm.proxy.$t("userText.auditIng");
  } else if (authStatus.value == 2) {
    text = vm.proxy.$t("userText.hasAuth");
  } else if (authStatus.value == 3) {
    text = vm.proxy.$t("userText.hasAuth");
  } else {
    text = vm.proxy.$t("userText.noAuth");
  }
  return text;
});
// 高级认证
const advancedAuth = computed(() => {
  let text;
  if (authStatus.value == 3) {
    text = vm.proxy.$t("userText.hasAuth");
  } else {
    text = vm.proxy.$t("userText.noAuth");
  }
  return text;
});
const countryInfo = ref({});
// 获取国家数据
const initData = async () => {
  const { message } = await getrealinfo({ id: userId.value });
  countryInfo.value = message;
};
// 认证弹框
const showAuth = ref(false);
const authType = ref("1"); // 1：初级认证  2：高级认证
// 进行初级认证
const handPrimary = () => {
  if (authStatus.value == "1") {
    Message.warning(vm.proxy.$t("userText.auditIng"));
    return false;
  }
  if (!isPrimaryAuth.value) {
    authType.value = "1";
    showAuth.value = true;
  }
};
//高级认证
const handAdvanced = () => {
  if (authStatus.value == "1") {
    Message.warning(vm.proxy.$t("userText.auditIng"));
    return false;
  }
  if (!isAdvancedAuth.value) {
    authType.value = "2";
    showAuth.value = true;
  }
};
const closeAuth = () => {
  showAuth.value = false;
};
// 关闭认证 - 重新获取数据
const resetData = () => {
  store.dispatch("user/getUserInfo");
  initData();
  showAuth.value = false;
};
onMounted(() => {
  store.dispatch("user/getUserInfo");
  initData();
});
</script>

<style lang="scss" scoped>
.memberCenter {
  background: $section-color;
  overflow-y: auto;
  .memberHeader {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    h3,
    p {
      margin: 0;
    }
    .userInfo {
      align-items: center;
      img {
        width: 80px;
        height: 80px;
      }
      .nameBox {
        h3 {
          font-weight: bold;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .authInfo {
      .authItem {
        font-size: 14px;
        margin-left: 20px;
      }
      p {
        opacity: 0.6;
      }
    }
  }
  .authContainer {
    display: flex;
    justify-content: space-around;
    padding-bottom: 20px;
    .detailAhtuItem {
      width: 30%;
      h2 {
        font-weight: bold;
      }
      p {
        font-size: 14px;
      }
      .authBtn {
        height: 50px;
        margin: 16px 0px 16px;
        cursor: pointer;
        box-sizing: border-box;
        font-weight: 600;
        background-color: transparent;
        border: 1px solid #5670f6;
        border-radius: 4px;
        width: 100%;
      }
      .authTip {
        color: #aaa;
      }
      .areaBox {
        .areItem {
          margin: 8px 0;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          span:first-child {
            color: #999;
          }
        }
      }
    }
  }
  .bottomInfoList {
    padding: 0 20px 20px;
    box-sizing: border-box;
    .lineItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      .rightLine {
        span {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
