<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    :title="title"
    width="700px"
    @closed="handClose"
  >
    <div class="authDig">
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        size="small"
        class="demo-ruleForm"
      >
        <!-- 初级 -->
        <template v-if="authType == '1'">
          <el-form-item :label="$t('userText.cityName')" prop="country">
            <el-select
              style="width: 100%"
              v-model="form.country"
              filterable
              remote
              reserve-keyword
              :placeholder="$t('tips.pleaseSelect')"
              :remote-method="getAreaData"
              :loading="areaLoading"
            >
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('userText.userName')" prop="name">
            <el-input
              v-model="form.name"
              :placeholder="$t('tips.pleaseEnter')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('userText.sureName')" prop="surname">
            <el-input
              v-model="form.surname"
              :placeholder="$t('tips.pleaseEnter')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('userText.cardType')" prop="card_type">
            <el-select
              style="width: 100%"
              v-model="form.card_type"
              :placeholder="$t('tips.pleaseSelect')"
            >
              <el-option
                v-for="item in cardList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('userText.cardNumber')" prop="card_id">
            <el-input
              v-model="form.card_id"
              :placeholder="$t('tips.pleaseEnter')"
            ></el-input>
          </el-form-item>
        </template>
        <!-- 高级 -->
        <template v-else>
          <el-form-item :label="$t('userText.idCardFront')" prop="front_pic">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="handleImgSuccessFront"
              :before-upload="beforeImgUpload"
              :headers="header"
            >
              <img v-if="form.front_pic" :src="form.front_pic" class="avatar" />
              <i class="el-icon-plus avatar-uploader-icon" v-else></i>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('userText.idCardBack')" prop="reverse_pic">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="handleImgSuccessBack"
              :before-upload="beforeImgUpload"
              :headers="header"
            >
              <img
                v-if="form.reverse_pic"
                :src="form.reverse_pic"
                class="avatar"
              />
              <i class="el-icon-plus avatar-uploader-icon" v-else></i>
            </el-upload>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{
        $t("tips.cancelText")
      }}</el-button>
      <el-button type="primary" @click="submitForm">{{
        $t("tips.submitText")
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script setup>
import {
  onMounted,
  reactive,
  ref,
  getCurrentInstance,
  computed,
  toRaw,
} from "vue";
import useDialog from "@/hooks/useDialog";
import { Message } from "element-ui";
import { real_name } from "@/api/user";
import { getareacode } from "@/api/common";
import { useOption } from "@/hooks/useOption";
import useUploadFile from "@/hooks/useUploadFile";
import { useLang } from "@/hooks/useLang";
const { action, beforeImgUpload, header } = useUploadFile();
const { cardList } = useOption();
const { lang } = useLang();
const props = defineProps({
  // 1：初级认证  2：高级认证
  authType: {
    type: String,
    default: "1",
  },
});
const emit = defineEmits(["close", 'resetData']);
const { proxy } = getCurrentInstance();
const { visible, handClose } = useDialog(emit);
const title = computed(() => {
  return props.authType == "1"
    ? proxy.$t("userText.primaryCertification")
    : proxy.$t("userText.advancedCertification");
});
const form = reactive({
  country: null, // 国家
  name: "", // 名字
  surname: "", // 姓氏
  card_type: null, // 证件类型
  card_id: "", // 证件编号
  front_pic: null, //正面
  reverse_pic: null, // 反面
});
const rules = ref({
  country: [
    {
      required: true,
      message: proxy.$t("tips.pleaseSelect"),
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: proxy.$t("tips.pleaseEnter"),
      trigger: "blur",
    },
  ],
  surname: [
    {
      required: true,
      message: proxy.$t("tips.pleaseEnter"),
      trigger: "blur",
    },
  ],
  card_type: [
    {
      required: true,
      message: proxy.$t("tips.pleaseSelect"),
      trigger: "change",
    },
  ],
  card_id: [
    {
      required: true,
      message: proxy.$t("tips.pleaseEnter"),
      trigger: "blur",
    },
  ],
  front_pic: [
    {
      required: true,
      message: proxy.$t("tips.pleaseUpload"),
      trigger: "change",
    },
  ],
  reverse_pic: [
    {
      required: true,
      message: proxy.$t("tips.pleaseUpload"),
      trigger: "change",
    },
  ],
});
const ruleForm = ref(null);
const submitForm = () => {
  ruleForm.value.validate(async (valid) => {
    if (valid) {
      let postData = {};
      if (props.authType == "1") {
        postData = {
          country: form.country,
          name: form.name,
          surname: form.surname,
          card_type: form.card_type,
          card_id: form.card_id,
        };
      } else {
        postData = {
          front_pic: form.front_pic,
          reverse_pic: form.reverse_pic,
        };
      }
      const { message, type } = await real_name({
        ...postData,
        lang: lang.value,
      });
      if (type == "ok") {
        Message({
          message: proxy.$t(message),
          type: "success",
        });
        emit("resetData");
      } else {
        Message({
          message: proxy.$t(message),
          type: "error",
        });
      }
    }
  });
};
const areaLoading = ref(false);
// 国家列表
const areaList = ref([]);
const getAreaData = async (name) => {
  areaLoading.value = true;
  const { message } = await getareacode({ name: name });
  areaList.value = message;
  areaLoading.value = false;
};
// 正面
const handleImgSuccessFront = (response) => {
  const { message } = response;
  form.front_pic = message;
};
// 反面
const handleImgSuccessBack = (response) => {
  const { message } = response;
  form.reverse_pic = message;
};
onMounted(() => {
  getAreaData();
});
</script>
<style lang="scss" scope>
.authDig {
}
.avatar-uploader .el-upload {
  border: 1px dashed $market-list-hover;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>
